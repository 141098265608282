import React from "react";

export const Features = (props) => {
    return (
        <div id="features" className="text-center">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>产品</h2>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.map((d, i) => (
                            d.url && d.url.length > 0 ?
                                <a href={d.url}>
                                    <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3 feature-div">
                                        {" "}
                                        <i className={d.icon}></i>
                                        <h3>{d.title}</h3>
                                        <p className="fp">{d.text}</p>
                                    </div>
                                </a>
                                : <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3 feature-div1">
                                    {" "}
                                    <i className={d.icon}></i>
                                    <h3>{d.title}</h3>
                                    <p className="fp">{d.text}</p>
                                </div>
                        ))

                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};
