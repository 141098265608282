import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("service_6wahy3d", "template_uqstnhd", e.target, "uW9UPN9aXJ50kJedS")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>联系我们</h2>
                <p>
                 请填写下面的表格，发送电子邮件给我们，我们将
                 我会尽快回复你。
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="姓名"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="邮箱"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="消息"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  发送消息
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>联系方式</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> 地址
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> 电话</span>{" "}{props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> 邮箱
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
{/*             <div className="row"> */}
{/*               <div className="social"> */}
{/*                 <ul> */}
{/*                   <li> */}
{/*                     <a href={props.data ? props.data.facebook : "/"}> */}
{/*                       <i className="fa fa-facebook"></i> */}
{/*                     </a> */}
{/*                   </li> */}
{/*                   <li> */}
{/*                     <a href={props.data ? props.data.twitter : "/"}> */}
{/*                       <i className="fa fa-twitter"></i> */}
{/*                     </a> */}
{/*                   </li> */}
{/*                   <li> */}
{/*                     <a href={props.data ? props.data.youtube : "/"}> */}
{/*                       <i className="fa fa-youtube"></i> */}
{/*                     </a> */}
{/*                   </li> */}
{/*                 </ul> */}
{/*               </div> */}
{/*             </div> */}
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            <a href="http://www.yuntun.group" rel="nofollow">
              &copy; 2023 北京云豚科技有限责任公司
            </a>
          </p>
          <p>
             <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="nofollow">
                       京ICP备2023036832号
             </a>
              {"     "}{"     "}
             <a href="https://beian.mps.gov.cn/#/query/webSearch" rel="nofollow">
                       京公网安备11010502054423号
             </a>
          </p>
        </div>
      </div>
    </div>
  );
};
