import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>团队介绍</h2>
          <p>
             公司的创始团队由来自国内外知名科技企业的顶尖技术专家组成，成员主要是硕士和博士学位持有者。团队专业涉及AI各领域，包括NLP、计算机视觉和联邦学习等，拥有丰富的专利和学术成果。成员们不仅有深厚的技术背景，还具备强大的架构设计和代码实现能力，能有效提升工程效能。
           </p>
        </div>
{/*         <div id="row"> */}
{/*           {props.data */}
{/*             ? props.data.map((d, i) => ( */}
{/*                 <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team"> */}
{/*                   <div className="thumbnail"> */}
{/*                     {" "} */}
{/*                     <img src={d.img} alt="..." className="team-img" /> */}
{/*                     <div className="caption"> */}
{/*                       <h4>{d.name}</h4> */}
{/*                       <p>{d.job}</p> */}
{/*                     </div> */}
{/*                   </div> */}
{/*                 </div> */}
{/*               )) */}
{/*             : "loading"} */}
{/*         </div> */}
      </div>
    </div>
  );
};
